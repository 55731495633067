/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGCSealItems = /* GraphQL */ `
  query GetGCSealItems($id: ID!) {
    getGCSealItems(id: $id) {
      id
      name
      costToBuy
      adamantoiseMarketPrice
      adamantoiseSalesLast24
      cactuarMarketPrice
      cactuarSalesLast24
      faerieMarketPrice
      faerieSalesLast24
      gilgameshMarketPrice
      gilgameshSalesLast24
      jenovaMarketPrice
      jenovaSalesLast24
      midgardsormrMarketPrice
      midgardsormrSalesLast24
      sargatanasMarketPrice
      sargatanasSalesLast24
      sirenMarketPrice
      sirenSalesLast24
      balmungMarketPrice
      balmungSalesLast24
      brynhildrMarketPrice
      brynhildrSalesLast24
      coeurlMarketPrice
      coeurlSalesLast24
      diabolosMarketPrice
      diabolosSalesLast24
      goblinMarketPrice
      goblinSalesLast24
      malboroMarketPrice
      malboroSalesLast24
      mateusMarketPrice
      mateusSalesLast24
      zaleraMarketPrice
      zaleraSalesLast24
      behemothMarketPrice
      behemothSalesLast24
      excaliburMarketPrice
      excaliburSalesLast24
      exodusMarketPrice
      exodusSalesLast24
      famfritMarketPrice
      famfritSalesLast24
      hyperionMarketPrice
      hyperionSalesLast24
      lamiaMarketPrice
      lamiaSalesLast24
      leviathanMarketPrice
      leviathanSalesLast24
      ultrosMarketPrice
      ultrosSalesLast24
      halicarnassusMarketPrice
      halicarnassusSalesLast24
      maduinMarketPrice
      maduinSalesLast24
      marilithMarketPrice
      marilithSalesLast24
      seraphMarketPrice
      seraphSalesLast24
      cerberusMarketPrice
      cerberusSalesLast24
      louisoixMarketPrice
      louisoixSalesLast24
      moogleMarketPrice
      moogleSalesLast24
      omegaMarketPrice
      omegaSalesLast24
      phantomMarketPrice
      phantomSalesLast24
      ragnarokMarketPrice
      ragnarokSalesLast24
      sagittariusMarketPrice
      sagittariusSalesLast24
      sprigganMarketPrice
      sprigganSalesLast24
      alphaMarketPrice
      alphaSalesLast24
      lichMarketPrice
      lichSalesLast24
      odinMarketPrice
      odinSalesLast24
      phoenixMarketPrice
      phoenixSalesLast24
      raidenMarketPrice
      raidenSalesLast24
      shivaMarketPrice
      shivaSalesLast24
      twintaniaMarketPrice
      twintaniaSalesLast24
      zodiarkMarketPrice
      zodiarkSalesLast24
      aegisMarketPrice
      aegisSalesLast24
      atomosMarketPrice
      atomosSalesLast24
      carbuncleMarketPrice
      carbuncleSalesLast24
      garudaMarketPrice
      garudaSalesLast24
      gungnirMarketPrice
      gungnirSalesLast24
      kujataMarketPrice
      kujataSalesLast24
      tonberryMarketPrice
      tonberrySalesLast24
      typhonMarketPrice
      typhonSalesLast24
      alexanderMarketPrice
      alexanderSalesLast24
      bahamutMarketPrice
      bahamutSalesLast24
      durandalMarketPrice
      durandalSalesLast24
      fenrirMarketPrice
      fenrirSalesLast24
      ifritMarketPrice
      ifritSalesLast24
      ridillMarketPrice
      ridillSalesLast24
      tiamatMarketPrice
      tiamatSalesLast24
      ultimaMarketPrice
      ultimaSalesLast24
      animaMarketPrice
      animaSalesLast24
      asuraMarketPrice
      asuraSalesLast24
      chocoboMarketPrice
      chocoboSalesLast24
      hadesMarketPrice
      hadesSalesLast24
      ixionMarketPrice
      ixionSalesLast24
      masamuneMarketPrice
      masamuneSalesLast24
      pandaemoniumMarketPrice
      pandaemoniumSalesLast24
      titanMarketPrice
      titanSalesLast24
      beliasMarketPrice
      beliasSalesLast24
      mandragoraMarketPrice
      mandragoraSalesLast24
      ramuhMarketPrice
      ramuhSalesLast24
      shinryuMarketPrice
      shinryuSalesLast24
      unicornMarketPrice
      unicornSalesLast24
      valeforMarketPrice
      valeforSalesLast24
      yojimboMarketPrice
      yojimboSalesLast24
      zeromusMarketPrice
      zeromusSalesLast24
      bismarckMarketPrice
      bismarckSalesLast24
      ravanaMarketPrice
      ravanaSalesLast24
      sephirotMarketPrice
      sephirotSalesLast24
      sophiaMarketPrice
      sophiaSalesLast24
      zurvanMarketPrice
      zurvanSalesLast24
      __typename
    }
  }
`;
export const listGCSealItems = /* GraphQL */ `
  query ListGCSealItems(
    $filter: ModelGCSealItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGCSealItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        costToBuy
        adamantoiseMarketPrice
        adamantoiseSalesLast24
        cactuarMarketPrice
        cactuarSalesLast24
        faerieMarketPrice
        faerieSalesLast24
        gilgameshMarketPrice
        gilgameshSalesLast24
        jenovaMarketPrice
        jenovaSalesLast24
        midgardsormrMarketPrice
        midgardsormrSalesLast24
        sargatanasMarketPrice
        sargatanasSalesLast24
        sirenMarketPrice
        sirenSalesLast24
        balmungMarketPrice
        balmungSalesLast24
        brynhildrMarketPrice
        brynhildrSalesLast24
        coeurlMarketPrice
        coeurlSalesLast24
        diabolosMarketPrice
        diabolosSalesLast24
        goblinMarketPrice
        goblinSalesLast24
        malboroMarketPrice
        malboroSalesLast24
        mateusMarketPrice
        mateusSalesLast24
        zaleraMarketPrice
        zaleraSalesLast24
        behemothMarketPrice
        behemothSalesLast24
        excaliburMarketPrice
        excaliburSalesLast24
        exodusMarketPrice
        exodusSalesLast24
        famfritMarketPrice
        famfritSalesLast24
        hyperionMarketPrice
        hyperionSalesLast24
        lamiaMarketPrice
        lamiaSalesLast24
        leviathanMarketPrice
        leviathanSalesLast24
        ultrosMarketPrice
        ultrosSalesLast24
        halicarnassusMarketPrice
        halicarnassusSalesLast24
        maduinMarketPrice
        maduinSalesLast24
        marilithMarketPrice
        marilithSalesLast24
        seraphMarketPrice
        seraphSalesLast24
        cerberusMarketPrice
        cerberusSalesLast24
        louisoixMarketPrice
        louisoixSalesLast24
        moogleMarketPrice
        moogleSalesLast24
        omegaMarketPrice
        omegaSalesLast24
        phantomMarketPrice
        phantomSalesLast24
        ragnarokMarketPrice
        ragnarokSalesLast24
        sagittariusMarketPrice
        sagittariusSalesLast24
        sprigganMarketPrice
        sprigganSalesLast24
        alphaMarketPrice
        alphaSalesLast24
        lichMarketPrice
        lichSalesLast24
        odinMarketPrice
        odinSalesLast24
        phoenixMarketPrice
        phoenixSalesLast24
        raidenMarketPrice
        raidenSalesLast24
        shivaMarketPrice
        shivaSalesLast24
        twintaniaMarketPrice
        twintaniaSalesLast24
        zodiarkMarketPrice
        zodiarkSalesLast24
        aegisMarketPrice
        aegisSalesLast24
        atomosMarketPrice
        atomosSalesLast24
        carbuncleMarketPrice
        carbuncleSalesLast24
        garudaMarketPrice
        garudaSalesLast24
        gungnirMarketPrice
        gungnirSalesLast24
        kujataMarketPrice
        kujataSalesLast24
        tonberryMarketPrice
        tonberrySalesLast24
        typhonMarketPrice
        typhonSalesLast24
        alexanderMarketPrice
        alexanderSalesLast24
        bahamutMarketPrice
        bahamutSalesLast24
        durandalMarketPrice
        durandalSalesLast24
        fenrirMarketPrice
        fenrirSalesLast24
        ifritMarketPrice
        ifritSalesLast24
        ridillMarketPrice
        ridillSalesLast24
        tiamatMarketPrice
        tiamatSalesLast24
        ultimaMarketPrice
        ultimaSalesLast24
        animaMarketPrice
        animaSalesLast24
        asuraMarketPrice
        asuraSalesLast24
        chocoboMarketPrice
        chocoboSalesLast24
        hadesMarketPrice
        hadesSalesLast24
        ixionMarketPrice
        ixionSalesLast24
        masamuneMarketPrice
        masamuneSalesLast24
        pandaemoniumMarketPrice
        pandaemoniumSalesLast24
        titanMarketPrice
        titanSalesLast24
        beliasMarketPrice
        beliasSalesLast24
        mandragoraMarketPrice
        mandragoraSalesLast24
        ramuhMarketPrice
        ramuhSalesLast24
        shinryuMarketPrice
        shinryuSalesLast24
        unicornMarketPrice
        unicornSalesLast24
        valeforMarketPrice
        valeforSalesLast24
        yojimboMarketPrice
        yojimboSalesLast24
        zeromusMarketPrice
        zeromusSalesLast24
        bismarckMarketPrice
        bismarckSalesLast24
        ravanaMarketPrice
        ravanaSalesLast24
        sephirotMarketPrice
        sephirotSalesLast24
        sophiaMarketPrice
        sophiaSalesLast24
        zurvanMarketPrice
        zurvanSalesLast24
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPoeticItems = /* GraphQL */ `
  query GetPoeticItems($id: ID!) {
    getPoeticItems(id: $id) {
      id
      name
      costToBuy
      adamantoiseMarketPrice
      adamantoiseSalesLast24
      cactuarMarketPrice
      cactuarSalesLast24
      faerieMarketPrice
      faerieSalesLast24
      gilgameshMarketPrice
      gilgameshSalesLast24
      jenovaMarketPrice
      jenovaSalesLast24
      midgardsormrMarketPrice
      midgardsormrSalesLast24
      sargatanasMarketPrice
      sargatanasSalesLast24
      sirenMarketPrice
      sirenSalesLast24
      balmungMarketPrice
      balmungSalesLast24
      brynhildrMarketPrice
      brynhildrSalesLast24
      coeurlMarketPrice
      coeurlSalesLast24
      diabolosMarketPrice
      diabolosSalesLast24
      goblinMarketPrice
      goblinSalesLast24
      malboroMarketPrice
      malboroSalesLast24
      mateusMarketPrice
      mateusSalesLast24
      zaleraMarketPrice
      zaleraSalesLast24
      behemothMarketPrice
      behemothSalesLast24
      excaliburMarketPrice
      excaliburSalesLast24
      exodusMarketPrice
      exodusSalesLast24
      famfritMarketPrice
      famfritSalesLast24
      hyperionMarketPrice
      hyperionSalesLast24
      lamiaMarketPrice
      lamiaSalesLast24
      leviathanMarketPrice
      leviathanSalesLast24
      ultrosMarketPrice
      ultrosSalesLast24
      halicarnassusMarketPrice
      halicarnassusSalesLast24
      maduinMarketPrice
      maduinSalesLast24
      marilithMarketPrice
      marilithSalesLast24
      seraphMarketPrice
      seraphSalesLast24
      cerberusMarketPrice
      cerberusSalesLast24
      louisoixMarketPrice
      louisoixSalesLast24
      moogleMarketPrice
      moogleSalesLast24
      omegaMarketPrice
      omegaSalesLast24
      phantomMarketPrice
      phantomSalesLast24
      ragnarokMarketPrice
      ragnarokSalesLast24
      sagittariusMarketPrice
      sagittariusSalesLast24
      sprigganMarketPrice
      sprigganSalesLast24
      alphaMarketPrice
      alphaSalesLast24
      lichMarketPrice
      lichSalesLast24
      odinMarketPrice
      odinSalesLast24
      phoenixMarketPrice
      phoenixSalesLast24
      raidenMarketPrice
      raidenSalesLast24
      shivaMarketPrice
      shivaSalesLast24
      twintaniaMarketPrice
      twintaniaSalesLast24
      zodiarkMarketPrice
      zodiarkSalesLast24
      aegisMarketPrice
      aegisSalesLast24
      atomosMarketPrice
      atomosSalesLast24
      carbuncleMarketPrice
      carbuncleSalesLast24
      garudaMarketPrice
      garudaSalesLast24
      gungnirMarketPrice
      gungnirSalesLast24
      kujataMarketPrice
      kujataSalesLast24
      tonberryMarketPrice
      tonberrySalesLast24
      typhonMarketPrice
      typhonSalesLast24
      alexanderMarketPrice
      alexanderSalesLast24
      bahamutMarketPrice
      bahamutSalesLast24
      durandalMarketPrice
      durandalSalesLast24
      fenrirMarketPrice
      fenrirSalesLast24
      ifritMarketPrice
      ifritSalesLast24
      ridillMarketPrice
      ridillSalesLast24
      tiamatMarketPrice
      tiamatSalesLast24
      ultimaMarketPrice
      ultimaSalesLast24
      animaMarketPrice
      animaSalesLast24
      asuraMarketPrice
      asuraSalesLast24
      chocoboMarketPrice
      chocoboSalesLast24
      hadesMarketPrice
      hadesSalesLast24
      ixionMarketPrice
      ixionSalesLast24
      masamuneMarketPrice
      masamuneSalesLast24
      pandaemoniumMarketPrice
      pandaemoniumSalesLast24
      titanMarketPrice
      titanSalesLast24
      beliasMarketPrice
      beliasSalesLast24
      mandragoraMarketPrice
      mandragoraSalesLast24
      ramuhMarketPrice
      ramuhSalesLast24
      shinryuMarketPrice
      shinryuSalesLast24
      unicornMarketPrice
      unicornSalesLast24
      valeforMarketPrice
      valeforSalesLast24
      yojimboMarketPrice
      yojimboSalesLast24
      zeromusMarketPrice
      zeromusSalesLast24
      bismarckMarketPrice
      bismarckSalesLast24
      ravanaMarketPrice
      ravanaSalesLast24
      sephirotMarketPrice
      sephirotSalesLast24
      sophiaMarketPrice
      sophiaSalesLast24
      zurvanMarketPrice
      zurvanSalesLast24
      __typename
    }
  }
`;
export const listPoeticItems = /* GraphQL */ `
  query ListPoeticItems(
    $filter: ModelPoeticItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPoeticItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        costToBuy
        adamantoiseMarketPrice
        adamantoiseSalesLast24
        cactuarMarketPrice
        cactuarSalesLast24
        faerieMarketPrice
        faerieSalesLast24
        gilgameshMarketPrice
        gilgameshSalesLast24
        jenovaMarketPrice
        jenovaSalesLast24
        midgardsormrMarketPrice
        midgardsormrSalesLast24
        sargatanasMarketPrice
        sargatanasSalesLast24
        sirenMarketPrice
        sirenSalesLast24
        balmungMarketPrice
        balmungSalesLast24
        brynhildrMarketPrice
        brynhildrSalesLast24
        coeurlMarketPrice
        coeurlSalesLast24
        diabolosMarketPrice
        diabolosSalesLast24
        goblinMarketPrice
        goblinSalesLast24
        malboroMarketPrice
        malboroSalesLast24
        mateusMarketPrice
        mateusSalesLast24
        zaleraMarketPrice
        zaleraSalesLast24
        behemothMarketPrice
        behemothSalesLast24
        excaliburMarketPrice
        excaliburSalesLast24
        exodusMarketPrice
        exodusSalesLast24
        famfritMarketPrice
        famfritSalesLast24
        hyperionMarketPrice
        hyperionSalesLast24
        lamiaMarketPrice
        lamiaSalesLast24
        leviathanMarketPrice
        leviathanSalesLast24
        ultrosMarketPrice
        ultrosSalesLast24
        halicarnassusMarketPrice
        halicarnassusSalesLast24
        maduinMarketPrice
        maduinSalesLast24
        marilithMarketPrice
        marilithSalesLast24
        seraphMarketPrice
        seraphSalesLast24
        cerberusMarketPrice
        cerberusSalesLast24
        louisoixMarketPrice
        louisoixSalesLast24
        moogleMarketPrice
        moogleSalesLast24
        omegaMarketPrice
        omegaSalesLast24
        phantomMarketPrice
        phantomSalesLast24
        ragnarokMarketPrice
        ragnarokSalesLast24
        sagittariusMarketPrice
        sagittariusSalesLast24
        sprigganMarketPrice
        sprigganSalesLast24
        alphaMarketPrice
        alphaSalesLast24
        lichMarketPrice
        lichSalesLast24
        odinMarketPrice
        odinSalesLast24
        phoenixMarketPrice
        phoenixSalesLast24
        raidenMarketPrice
        raidenSalesLast24
        shivaMarketPrice
        shivaSalesLast24
        twintaniaMarketPrice
        twintaniaSalesLast24
        zodiarkMarketPrice
        zodiarkSalesLast24
        aegisMarketPrice
        aegisSalesLast24
        atomosMarketPrice
        atomosSalesLast24
        carbuncleMarketPrice
        carbuncleSalesLast24
        garudaMarketPrice
        garudaSalesLast24
        gungnirMarketPrice
        gungnirSalesLast24
        kujataMarketPrice
        kujataSalesLast24
        tonberryMarketPrice
        tonberrySalesLast24
        typhonMarketPrice
        typhonSalesLast24
        alexanderMarketPrice
        alexanderSalesLast24
        bahamutMarketPrice
        bahamutSalesLast24
        durandalMarketPrice
        durandalSalesLast24
        fenrirMarketPrice
        fenrirSalesLast24
        ifritMarketPrice
        ifritSalesLast24
        ridillMarketPrice
        ridillSalesLast24
        tiamatMarketPrice
        tiamatSalesLast24
        ultimaMarketPrice
        ultimaSalesLast24
        animaMarketPrice
        animaSalesLast24
        asuraMarketPrice
        asuraSalesLast24
        chocoboMarketPrice
        chocoboSalesLast24
        hadesMarketPrice
        hadesSalesLast24
        ixionMarketPrice
        ixionSalesLast24
        masamuneMarketPrice
        masamuneSalesLast24
        pandaemoniumMarketPrice
        pandaemoniumSalesLast24
        titanMarketPrice
        titanSalesLast24
        beliasMarketPrice
        beliasSalesLast24
        mandragoraMarketPrice
        mandragoraSalesLast24
        ramuhMarketPrice
        ramuhSalesLast24
        shinryuMarketPrice
        shinryuSalesLast24
        unicornMarketPrice
        unicornSalesLast24
        valeforMarketPrice
        valeforSalesLast24
        yojimboMarketPrice
        yojimboSalesLast24
        zeromusMarketPrice
        zeromusSalesLast24
        bismarckMarketPrice
        bismarckSalesLast24
        ravanaMarketPrice
        ravanaSalesLast24
        sephirotMarketPrice
        sephirotSalesLast24
        sophiaMarketPrice
        sophiaSalesLast24
        zurvanMarketPrice
        zurvanSalesLast24
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSecondaryTomeItems = /* GraphQL */ `
  query GetSecondaryTomeItems($id: ID!) {
    getSecondaryTomeItems(id: $id) {
      id
      name
      costToBuy
      adamantoiseMarketPrice
      adamantoiseSalesLast24
      cactuarMarketPrice
      cactuarSalesLast24
      faerieMarketPrice
      faerieSalesLast24
      gilgameshMarketPrice
      gilgameshSalesLast24
      jenovaMarketPrice
      jenovaSalesLast24
      midgardsormrMarketPrice
      midgardsormrSalesLast24
      sargatanasMarketPrice
      sargatanasSalesLast24
      sirenMarketPrice
      sirenSalesLast24
      balmungMarketPrice
      balmungSalesLast24
      brynhildrMarketPrice
      brynhildrSalesLast24
      coeurlMarketPrice
      coeurlSalesLast24
      diabolosMarketPrice
      diabolosSalesLast24
      goblinMarketPrice
      goblinSalesLast24
      malboroMarketPrice
      malboroSalesLast24
      mateusMarketPrice
      mateusSalesLast24
      zaleraMarketPrice
      zaleraSalesLast24
      behemothMarketPrice
      behemothSalesLast24
      excaliburMarketPrice
      excaliburSalesLast24
      exodusMarketPrice
      exodusSalesLast24
      famfritMarketPrice
      famfritSalesLast24
      hyperionMarketPrice
      hyperionSalesLast24
      lamiaMarketPrice
      lamiaSalesLast24
      leviathanMarketPrice
      leviathanSalesLast24
      ultrosMarketPrice
      ultrosSalesLast24
      halicarnassusMarketPrice
      halicarnassusSalesLast24
      maduinMarketPrice
      maduinSalesLast24
      marilithMarketPrice
      marilithSalesLast24
      seraphMarketPrice
      seraphSalesLast24
      cerberusMarketPrice
      cerberusSalesLast24
      louisoixMarketPrice
      louisoixSalesLast24
      moogleMarketPrice
      moogleSalesLast24
      omegaMarketPrice
      omegaSalesLast24
      phantomMarketPrice
      phantomSalesLast24
      ragnarokMarketPrice
      ragnarokSalesLast24
      sagittariusMarketPrice
      sagittariusSalesLast24
      sprigganMarketPrice
      sprigganSalesLast24
      alphaMarketPrice
      alphaSalesLast24
      lichMarketPrice
      lichSalesLast24
      odinMarketPrice
      odinSalesLast24
      phoenixMarketPrice
      phoenixSalesLast24
      raidenMarketPrice
      raidenSalesLast24
      shivaMarketPrice
      shivaSalesLast24
      twintaniaMarketPrice
      twintaniaSalesLast24
      zodiarkMarketPrice
      zodiarkSalesLast24
      aegisMarketPrice
      aegisSalesLast24
      atomosMarketPrice
      atomosSalesLast24
      carbuncleMarketPrice
      carbuncleSalesLast24
      garudaMarketPrice
      garudaSalesLast24
      gungnirMarketPrice
      gungnirSalesLast24
      kujataMarketPrice
      kujataSalesLast24
      tonberryMarketPrice
      tonberrySalesLast24
      typhonMarketPrice
      typhonSalesLast24
      alexanderMarketPrice
      alexanderSalesLast24
      bahamutMarketPrice
      bahamutSalesLast24
      durandalMarketPrice
      durandalSalesLast24
      fenrirMarketPrice
      fenrirSalesLast24
      ifritMarketPrice
      ifritSalesLast24
      ridillMarketPrice
      ridillSalesLast24
      tiamatMarketPrice
      tiamatSalesLast24
      ultimaMarketPrice
      ultimaSalesLast24
      animaMarketPrice
      animaSalesLast24
      asuraMarketPrice
      asuraSalesLast24
      chocoboMarketPrice
      chocoboSalesLast24
      hadesMarketPrice
      hadesSalesLast24
      ixionMarketPrice
      ixionSalesLast24
      masamuneMarketPrice
      masamuneSalesLast24
      pandaemoniumMarketPrice
      pandaemoniumSalesLast24
      titanMarketPrice
      titanSalesLast24
      beliasMarketPrice
      beliasSalesLast24
      mandragoraMarketPrice
      mandragoraSalesLast24
      ramuhMarketPrice
      ramuhSalesLast24
      shinryuMarketPrice
      shinryuSalesLast24
      unicornMarketPrice
      unicornSalesLast24
      valeforMarketPrice
      valeforSalesLast24
      yojimboMarketPrice
      yojimboSalesLast24
      zeromusMarketPrice
      zeromusSalesLast24
      bismarckMarketPrice
      bismarckSalesLast24
      ravanaMarketPrice
      ravanaSalesLast24
      sephirotMarketPrice
      sephirotSalesLast24
      sophiaMarketPrice
      sophiaSalesLast24
      zurvanMarketPrice
      zurvanSalesLast24
      __typename
    }
  }
`;
export const listSecondaryTomeItems = /* GraphQL */ `
  query ListSecondaryTomeItems(
    $filter: ModelSecondaryTomeItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSecondaryTomeItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        costToBuy
        adamantoiseMarketPrice
        adamantoiseSalesLast24
        cactuarMarketPrice
        cactuarSalesLast24
        faerieMarketPrice
        faerieSalesLast24
        gilgameshMarketPrice
        gilgameshSalesLast24
        jenovaMarketPrice
        jenovaSalesLast24
        midgardsormrMarketPrice
        midgardsormrSalesLast24
        sargatanasMarketPrice
        sargatanasSalesLast24
        sirenMarketPrice
        sirenSalesLast24
        balmungMarketPrice
        balmungSalesLast24
        brynhildrMarketPrice
        brynhildrSalesLast24
        coeurlMarketPrice
        coeurlSalesLast24
        diabolosMarketPrice
        diabolosSalesLast24
        goblinMarketPrice
        goblinSalesLast24
        malboroMarketPrice
        malboroSalesLast24
        mateusMarketPrice
        mateusSalesLast24
        zaleraMarketPrice
        zaleraSalesLast24
        behemothMarketPrice
        behemothSalesLast24
        excaliburMarketPrice
        excaliburSalesLast24
        exodusMarketPrice
        exodusSalesLast24
        famfritMarketPrice
        famfritSalesLast24
        hyperionMarketPrice
        hyperionSalesLast24
        lamiaMarketPrice
        lamiaSalesLast24
        leviathanMarketPrice
        leviathanSalesLast24
        ultrosMarketPrice
        ultrosSalesLast24
        halicarnassusMarketPrice
        halicarnassusSalesLast24
        maduinMarketPrice
        maduinSalesLast24
        marilithMarketPrice
        marilithSalesLast24
        seraphMarketPrice
        seraphSalesLast24
        cerberusMarketPrice
        cerberusSalesLast24
        louisoixMarketPrice
        louisoixSalesLast24
        moogleMarketPrice
        moogleSalesLast24
        omegaMarketPrice
        omegaSalesLast24
        phantomMarketPrice
        phantomSalesLast24
        ragnarokMarketPrice
        ragnarokSalesLast24
        sagittariusMarketPrice
        sagittariusSalesLast24
        sprigganMarketPrice
        sprigganSalesLast24
        alphaMarketPrice
        alphaSalesLast24
        lichMarketPrice
        lichSalesLast24
        odinMarketPrice
        odinSalesLast24
        phoenixMarketPrice
        phoenixSalesLast24
        raidenMarketPrice
        raidenSalesLast24
        shivaMarketPrice
        shivaSalesLast24
        twintaniaMarketPrice
        twintaniaSalesLast24
        zodiarkMarketPrice
        zodiarkSalesLast24
        aegisMarketPrice
        aegisSalesLast24
        atomosMarketPrice
        atomosSalesLast24
        carbuncleMarketPrice
        carbuncleSalesLast24
        garudaMarketPrice
        garudaSalesLast24
        gungnirMarketPrice
        gungnirSalesLast24
        kujataMarketPrice
        kujataSalesLast24
        tonberryMarketPrice
        tonberrySalesLast24
        typhonMarketPrice
        typhonSalesLast24
        alexanderMarketPrice
        alexanderSalesLast24
        bahamutMarketPrice
        bahamutSalesLast24
        durandalMarketPrice
        durandalSalesLast24
        fenrirMarketPrice
        fenrirSalesLast24
        ifritMarketPrice
        ifritSalesLast24
        ridillMarketPrice
        ridillSalesLast24
        tiamatMarketPrice
        tiamatSalesLast24
        ultimaMarketPrice
        ultimaSalesLast24
        animaMarketPrice
        animaSalesLast24
        asuraMarketPrice
        asuraSalesLast24
        chocoboMarketPrice
        chocoboSalesLast24
        hadesMarketPrice
        hadesSalesLast24
        ixionMarketPrice
        ixionSalesLast24
        masamuneMarketPrice
        masamuneSalesLast24
        pandaemoniumMarketPrice
        pandaemoniumSalesLast24
        titanMarketPrice
        titanSalesLast24
        beliasMarketPrice
        beliasSalesLast24
        mandragoraMarketPrice
        mandragoraSalesLast24
        ramuhMarketPrice
        ramuhSalesLast24
        shinryuMarketPrice
        shinryuSalesLast24
        unicornMarketPrice
        unicornSalesLast24
        valeforMarketPrice
        valeforSalesLast24
        yojimboMarketPrice
        yojimboSalesLast24
        zeromusMarketPrice
        zeromusSalesLast24
        bismarckMarketPrice
        bismarckSalesLast24
        ravanaMarketPrice
        ravanaSalesLast24
        sephirotMarketPrice
        sephirotSalesLast24
        sophiaMarketPrice
        sophiaSalesLast24
        zurvanMarketPrice
        zurvanSalesLast24
        __typename
      }
      nextToken
      __typename
    }
  }
`;
